import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import MenuBlock from './MenuBlock';

const Menu = ({ ...props }) => (
  <Grid.Row className="hidden md:flex md:justify-start">
    <Grid.Col className="p-[2px]" sm={12} md={1} />
    {props.children.map((elem, i) => (
      <Grid.Col className="p-[2px]" key={i} sm={12} md={2}>
        {elem}
      </Grid.Col>
    ))}
    <Grid.Col className="p-[2px]" sm={12} md={1} />
  </Grid.Row>
);

Menu.Block = MenuBlock;

export default Menu;
