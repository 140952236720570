import * as React from 'react';
import Copyright from 'styleguide/components/Footer/BottomPart/Copyright';
import Links from 'styleguide/components/Footer/BottomPart/Links';

const BottomPartMobile = () => (
  <div className="mb-8 flex w-full flex-col items-center justify-between text-xs">
    <div>
      <Copyright />
    </div>
    <div className="flex w-full flex-grow flex-row justify-around text-xs">
      <Links />
    </div>
  </div>
);

export default BottomPartMobile;
