import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import { format } from 'date-fns';
import cn from 'classnames';

interface Props {
  className?: string;
  isEnterprise?: boolean;
}

const Copyright = ({ className = '', isEnterprise = false }: Props) => (
  <Span className={cn('pb-4 pt-10 text-xs md:py-0', className)}>
    &copy; Printivity {isEnterprise ? 'Enterprise Solutions' : 'LLC'}. All Rights Reserved{' '}
    {format(new Date(), 'yyyy')}
  </Span>
);

export default Copyright;
