import * as React from 'react';
import Loader from 'styleguide/components/Loader/Loader';

const LoaderWrapper = loaderStyle => (
  <div className={loaderStyle}>
    <Loader size="xl" color="blue" />
  </div>
);

export default LoaderWrapper;
