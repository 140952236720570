import * as React from 'react';
import Span from 'styleguide/components/Span/Span';

interface Props {
  title: string;
  children?: React.ReactNode;
  className?: string;
}

const MenuBlock = ({ title, children, ...props }: Props) => (
  <div className={props.className}>
    <Span className="mb-3 inline-block font-hvBold text-lg font-bold leading-6">{title}</Span>
    <div className="flex flex-col text-base children:px-0 children:py-1">{children}</div>
  </div>
);

export default MenuBlock;
