import * as React from 'react';
import cn from 'classnames';
import Span from 'styleguide/components/Span/Span';
import Link from 'styleguide/components/Links/Link';
import { privacyPath, siteMapPath, termsAndConditionsPath } from 'bundles/App/routes';

const Links = () => (
  <>
    <Span className={cn('text-xs -md:hidden')}>|</Span>
    <Link to={siteMapPath} className="!text-xs" color="black" underline="none">
      Site Map
    </Link>
    <Span className={cn('text-xs -md:hidden')}>|</Span>
    <Link to={privacyPath} className="!text-xs" color="black" underline="none">
      Privacy Policy
    </Link>
    <Span className={cn('text-xs -md:hidden')}>|</Span>
    <Link to={termsAndConditionsPath} className="!text-xs" color="black" underline="none">
      Terms of Use
    </Link>
  </>
);

export default Links;
