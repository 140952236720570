import * as React from 'react';
import Links from './Links';
import Copyright from 'styleguide/components/Footer/BottomPart/Copyright';

const BottomPart = () => (
  <div className="mb-8 flex w-full flex-col items-center justify-around text-xs md:flex-row md:items-baseline">
    <Copyright />
    <Links />
  </div>
);

export default BottomPart;
