import * as React from 'react';
import cn from 'classnames';

const IfMobile = ({
  className,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn('block w-full text-center md:!hidden', className)} {...otherProps}>
    {children}
  </div>
);

export default IfMobile;
