import * as React from 'react';
import cn from 'classnames';

export interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const IfTabletOrDesktop = ({ className, children, ...otherProps }: Props) => (
  <div className={cn('hidden w-full md:mb-1 md:mt-4 md:block md:text-center', className)} {...otherProps}>
    {children}
  </div>
);

export default IfTabletOrDesktop;
